import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header>
        <img src="/public/images/Logo-PVT.png" alt="Logo" className="logoHeader"/>
      </header>
      <main>
        <p className="main-text">ATENDIMENTO TÉCNICO PARA FABRICANTES DE TINTAS.</p>
      </main>
      <footer>
        <div className="footer-content">
          <div className="contact">
            <a href="https://api.whatsapp.com/send?phone=551125966684" target="_blank" rel="noopener noreferrer">
              <img src="/public/images/whatsbranco.png" alt="WhatsApp Logo" className="whats" />
            </a>
            <span>(11) 2596-6684</span>
          </div>
          <div className="contact">
            <a href="mailto:sac@pvtconsultoria.com.br">
              <img src="/public/images/email1.png" alt="Email Logo" className="email"/>
            </a>
            <span>sac@pvtconsultoria.com.br</span>
          </div>
        </div>
        <p className="copyright">&copy; 2024 PVT Consultoria</p>
      </footer>
    </div>
  );
}

export default App;
